@tailwind base;
@tailwind components;
@tailwind utilities;

/* import antd styles */
@import "~antd/dist/antd.css";
@import "~hover.css/css/hover-min.css";
@import "./antd-custom.css";

@layer components {
  .btn-yellow {
    @apply bg-c-yellow text-c-black hover:bg-c-yellow hover:text-c-black focus:bg-c-yellow focus:text-c-blue font-bold border-0;
  }

  .btn-default {
    @apply bg-c-light text-c-black hover:bg-c-light hover:text-c-black focus:bg-c-light focus:text-c-blue font-bold border-0;
  }

  .btn-danger {
    @apply bg-c-red text-white hover:bg-c-red hover:text-white focus:bg-c-red focus:text-white font-bold border-0;
  }

  .btn-dark {
    @apply bg-c-black text-white hover:bg-c-black hover:text-white focus:bg-c-black focus:text-white font-bold border-0;
  }

  .btn-transparent {
    @apply bg-transparent text-white hover:bg-transparent hover:text-white focus:bg-transparent focus:text-white font-bold border border-white;
  }

  .container {
    width: 100%;
    max-width: 1100px;
  }

  .container-lg {
    width: 100%;
    max-width: 1400px;
  }

  .container-xl {
    width: 100%;
    max-width: 1600px;
  }

  .container-3xl {
    width: 100%;
    max-width: 1900px;
  }
}
.container .react-calendar__tile:disabled {
  background-color: #4a9d7f !important;
  color: white !important;
}
.container .react-calendar .yellow {
  background-color: yellow !important;
  color: black !important;
}

.container .react-calendar {
  border-color: white !important;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
}

.container .react-calendar__tile.react-calendar__month-view__days__day abbr {
  display: none !important;
}

.border-red-500 {
  border-width: 2px;
}
